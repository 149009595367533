import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Vend from 'assets/images/Vend.svg';

function ErrorFallback() {
  return (
    <Box p={4}>
      <img src={Vend} alt="VendPark" height="48px" />
      <Typography variant="h5" mt={8}>
        An Error Occurred
      </Typography>
      <Typography my={4}>
        Our team have been notified. Please refresh and if the issue persits,
        contact support.
      </Typography>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => window.location.reload()}
      >
        Reload the page
      </Button>
    </Box>
  );
}

export default ErrorFallback;
